<template>
    <div>
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>课程管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/AllCourseList' }">课程列表</el-breadcrumb-item>
            <el-breadcrumb-item>添加课程</el-breadcrumb-item>
        </el-breadcrumb> -->
        <el-card>
            <el-alert title="添加课程" type="info" center show-icon :closable="true"> </el-alert>
            <el-steps align-center :space="200" :active="ActiveIndex - 0" 
                finish-status="success">
                    <el-step title="基本信息"></el-step>
                    <el-step title="课程文件"></el-step>
                    <el-step title="完成"></el-step>
            </el-steps>
            <el-form :model="AddCourseForm" :rules="AddCourseFormRules"
                ref="AddCourseFormRef" label-width="100px" label-position="left">
                <el-tabs v-model="ActiveIndex" :tab-position="'left'" 
                :before-leave="BeforeTabLeave"
                @tab-click="TabClick">
                    <el-tab-pane label="基本信息" name="0">
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="课程名称：" prop="Name">
                                    <el-input v-model="AddCourseForm.Name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span=12>
                                <el-form-item label="课程描述：" prop="Code">
                                    <el-input v-model="AddCourseForm.Description"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="课程文件" name="1">
                        <el-alert title="文件提交后将不可修改" type="info" center show-icon :closable="true"> </el-alert>
                        <el-row>
                            <el-col>
                                <el-upload
                                :action="UploadImageUrl"
                                :on-remove="HandleRemove"
                                :on-preview="HandlePreview"
                                list-type="picture"
                                :headers="HeaderObj"
                                :on-success="UploadSuccess">
                                    <el-button type="primary">点击上传图片</el-button>
                                    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
                                </el-upload>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                <el-upload
                                style="margin:10px"
                                :action="UploadFileUrl"
                                :on-remove="HandleRemove"
                                list-type="text"
                                :headers="HeaderObj"
                                :on-success="UploadSuccess">
                                    <el-button type="primary">点击上传文件</el-button>
                                </el-upload>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="4">
                                <el-button type="primary" class="btn_add" @click="SubmitCourse">添加课程</el-button>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return{
            UploadImageUrl:'http://localhost:5000/CourseImage/Upload',
            ImagePreviewUrl:'http://localhost:5000/CourseImage/CourseImage?filename=',
            UploadFileUrl:'http://localhost:5000/CourseFile/Upload',
            ActiveIndex:0,
            AddCourseForm:{
                Name:'',
                Description:'',
                FileUrls:[]
            },
            AddCourseFormRules:{
                Name:[
                    { required: true, message: '请输入课程名称', trigger: 'blur' }
                ],
            },
            HeaderObj:{
                Authorization:sessionStorage.getItem('token')
            },
        }
    },
    created(){

    },
    methods:{
        BeforeTabLeave(activeName,oldActiveName){
            if(oldActiveName === '0' ){ 
                if(this.AddCourseForm.Name.trim().length === 0){
                    this.$message.error('请输入课程名称')
                    return false
                }
            }  
        },
        TabClick(){

        },
        HandleRemove(file){
            const filename =  file.name
            const index = this.AddCourseForm.FileUrls.findIndex(x => x === filename)
            this.AddCourseForm.FileUrls.splice(index,1)
        },
        HandlePreview(file){
            this.ImagePreviewUrl = this.ImagePreviewUrl + file.name
            console.log(this.ImagePreviewUrl)
            this.PreviewDialogVisible = true
        },
        UploadSuccess(response){
            response.data.forEach(element => {
                 this.AddCourseForm.FileUrls.push(element)
            });
           
        },
        async SubmitCourse(){
            this.$refs.AddCourseFormRef.validate(valid =>{
                this.validret = valid
            })
            if(!this.validret){
                return
            }
            await this.$http.post('Course/v1/CourseAndFile',this.AddCourseForm).
            then(function (response) {
                if(response.data.code != 1)
                {
                    return this.$message.error('添加课程失败')
                }
            }).catch(function (error) {
                return this.$message.error(error)
            });
            this.validret = false
            this.$message.success('添加成功');
            this.$router.push('/AllCourseList')
        }
    }
}
</script>